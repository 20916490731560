import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import LeftMenu from "../../components/leftMenu";
import { useSelector, useDispatch } from "react-redux";
import AlertModal from "../../components/alertModal";
import { Config } from "../../common/config";
import axios from "axios";
import {
  formatStrNumber,
  formatNumNumber,
  getFormattedDateTime,
} from "../../components/utils";
import Pagination from "../../components/pagination";
import { userLogin } from "../../reducers/userInfo";

export default function GiftList(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.userInfo);
  console.log(userInfo);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    isCancel: false,
    isConfirm: true,
    isMessage: true,
    headerMsg: "",
    actionFlag: "",
    mainMsg: "",
    action: "",
    isPercent: false,
  });
  const today = getFormattedDateTime(new Date());
  // console.log("today : "+new Date());
  const [searchCondition, setSearchCondition] = useState({
    flag: false,
    pageSize: Config.PAGE_SIZE_LIST[0],
    sortOrd: "",
    // startDttm: today.slice(0, 10),
    // endDttm: today.slice(0, 10),
    startDttm: "",
    endDttm: "",
    giftIdOrd: "",
    saleOrd: "",
    state: "",
  });
  const [total, setTotal] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);

  const openModal = (e) => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const doModal = () => {
    setModalOpen(false);
    if (modalInfo.actionFlag === "goMain") {
      navigate("/go-main");
    }
  };

  const modGift = (giftNo) => {
    const passData = {
      giftNo: giftNo,
    };
    // console.log("passData", JSON.stringify(passData));
    if (giftNo === 0) {
      // console.log("giftNo : 0");
      navigate("/gift-content");
    } else {
      // console.log("giftNo : > 0");
      navigate("/gift-manage", { state: passData });
    }
  };

  const sales = (giftId) => {
    const passData = {
      giftId: giftId,
    };
    // console.log("passData", JSON.stringify(passData));
    navigate("/gift-sales", { state: passData });
  };
  const refetchJwt = async () => {
    console.log(userInfo);
    try {
      const response = await axios.post(`${Config.API_SERVER}auth/admin/login`, {
        adminId: userInfo.id,
        password: userInfo.pwd,
      });

      console.log("jwt재발급: ", response.data.data.accessToken);
      const jwtToken = response.data.data.accessToken;
      const user = {
        ...userInfo,
        jwtToken: jwtToken,
      };
      console.log(user);
      dispatch(userLogin(user));
      getData(jwtToken);
    } catch (e) {
      console.log(e);
    }
  };

  async function getData(newToken) {
    // console.log("===== searchCondition.searchSection : "+JSON.stringify(searchCondition));
    try {
      let params = "?storeNo=" + userInfo.storeNo;
      // let params = "?storeNo=4";
      params += "&historyYn=Y";
      params += "&pageNo=" + currentPage;
      params += "&pageSize=" + searchCondition.pageSize;
      if (searchCondition.sortOrd.length > 0) {
        params += "&sortOrd=" + searchCondition.sortOrd;
      }
      if (searchCondition.startDttm.length > 0) {
        params += "&startDttm=" + searchCondition.startDttm;
      }
      if (searchCondition.endDttm.length > 0) {
        params += "&endDttm=" + searchCondition.endDttm;
      }
      if (searchCondition.giftIdOrd.length > 0) {
        params += "&giftIdOrd=" + searchCondition.giftIdOrd;
      }
      if (searchCondition.saleOrd.length > 0) {
        params += "&saleOrd=" + searchCondition.saleOrd;
      }
      if (searchCondition.state.length > 0) {
        params += "&state=" + searchCondition.state;
      }
      // console.log("params : "+params);

      const ret = await axios.get(Config.API_SERVER + "gift" + params, {
        headers: {
          Authorization: `Bearer ${newToken ? newToken : userInfo.jwtToken}`,
        },
      });

      if (ret?.data?.data) {
        const count = ret.data.data.total;
        // console.log("Count : "+count);
        const tp = Math.ceil(count / searchCondition.pageSize);
        // console.log("tp : "+tp);

        setTotalPage(tp);
        setTotal(count);
        // console.log("List : "+JSON.stringify(ret.data.data.boardList));
        setData(ret.data.data.list);
      }
      setSearchCondition({ ...searchCondition, flag: false });
    } catch (error) {
      if (error.response.data.resultCode === 401) {
        const autologin = localStorage.getItem("autologin");
        console.log(autologin);
        if (autologin) {
          refetchJwt();
        } else {
          modalInfo.actionFlag = "login";
          modalInfo.mainMsg = "유효기간이 만료되었으니 다시 로그인해주세요";
          setModalOpen(true);
        }
      }
      console.error("Error getData:", error);
    }
  }

  const pageChange = (page) => {
    // console.log(`Page changed to ${page}`);
    setCurrentPage(page);
  };

  const getSearch = () => {
    // console.log(`Page changed to ${page}`);
    setSearchCondition({ ...searchCondition, flag: true });
  };

  const getClear = () => {
    setSearchCondition({
      ...searchCondition,
      sortOrd: "",
      // startDttm: today.slice(0, 10),
      // endDttm: today.slice(0, 10),
      startDttm: "",
      endDttm: "",
      giftIdOrd: "",
      saleOrd: "",
      state: "",
    });
  };

  useEffect(() => {
    if (userInfo?.userNo !== "") {
      getData();
    }
  }, [currentPage, searchCondition.flag, userInfo]);

  return (
    <>
      <AlertModal
        open={modalOpen}
        close={closeModal}
        action={doModal}
        header={modalInfo.headerMsg}
        isConfirm={modalInfo.isConfirm}
        isMessage={modalInfo.isMessage}
        isCancel={modalInfo.isCancel}
      >
        {modalInfo.mainMsg}
      </AlertModal>

      <section className="location_box">
        <ul className="location_001">
          <li>홈</li>
          <li>마이페이지</li>
          <li>기프트 관리</li>
        </ul>
      </section>

      <main>
        <section className="contants_box contants_box_mypage">
          <LeftMenu pageName="gift" />

          <section className="rightcontant_box">
            <h2 className="tit_01">기프트 관리</h2>

            <article className="table_search_box">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <form>
                        조회기간&ensp;
                        <input
                          className="search_input_01"
                          type="date"
                          name=""
                          value={searchCondition.startDttm}
                          onChange={(event) =>
                            setSearchCondition({
                              ...searchCondition,
                              startDttm: event.target.value,
                            })
                          }
                        />
                        &nbsp;~&nbsp;
                        <input
                          className="search_input_01"
                          type="date"
                          name=""
                          value={searchCondition.endDttm}
                          onChange={(event) =>
                            setSearchCondition({
                              ...searchCondition,
                              endDttm: event.target.value,
                            })
                          }
                        />
                      </form>
                    </td>
                    <td>
                      <form>
                        <select
                          className="search_input_02"
                          value={searchCondition.giftIdOrd}
                          onChange={(event) =>
                            setSearchCondition({
                              ...searchCondition,
                              giftIdOrd: event.target.value,
                            })
                          }
                        >
                          <option
                            value=""
                            selected={searchCondition.giftIdOrd === ""}
                          >
                            --기프트 아이디--
                          </option>
                          <option
                            value="desc"
                            selected={searchCondition.giftIdOrd === "desc"}
                          >
                            내림차순
                          </option>
                          <option
                            value="asc"
                            selected={searchCondition.giftIdOrd === "asc"}
                          >
                            오름차순
                          </option>
                        </select>
                      </form>
                    </td>
                    <td>
                      <form>
                        <select
                          className="search_input_01"
                          value={searchCondition.saleOrd}
                          onChange={(event) =>
                            setSearchCondition({
                              ...searchCondition,
                              saleOrd: event.target.value,
                            })
                          }
                        >
                          <option
                            value=""
                            selected={searchCondition.saleOrd === ""}
                          >
                            --판매수량--
                          </option>
                          <option
                            value="desc"
                            selected={searchCondition.saleOrd === "desc"}
                          >
                            내림차순
                          </option>
                          <option
                            value="asc"
                            selected={searchCondition.saleOrd === "asc"}
                          >
                            오름차순
                          </option>
                        </select>
                      </form>
                    </td>
                    <td>
                      <form>
                        <select
                          className="search_input_01"
                          value={searchCondition.state}
                          onChange={(event) =>
                            setSearchCondition({
                              ...searchCondition,
                              state: event.target.value,
                            })
                          }
                        >
                          <option
                            value=""
                            selected={searchCondition.state === ""}
                          >
                            --상태--
                          </option>
                          <option
                            value="SS01"
                            selected={searchCondition.state === "SS01"}
                          >
                            판매전
                          </option>
                          <option
                            value="SS02"
                            selected={searchCondition.state === "SS02"}
                          >
                            판매중
                          </option>
                          <option
                            value="SS04"
                            selected={searchCondition.state === "SS04"}
                          >
                            판매종료
                          </option>
                        </select>
                      </form>
                    </td>
                    <td>
                      <form>
                        <select
                          className="search_input_01"
                          value={searchCondition.sortOrd}
                          onChange={(event) =>
                            setSearchCondition({
                              ...searchCondition,
                              sortOrd: event.target.value,
                            })
                          }
                        >
                          <option
                            value=""
                            selected={searchCondition.sortOrd === ""}
                          >
                            --등록일--
                          </option>
                          <option
                            value="desc"
                            selected={searchCondition.sortOrd === "desc"}
                          >
                            내림차순
                          </option>
                          <option
                            value="asc"
                            selected={searchCondition.sortOrd === "asc"}
                          >
                            오름차순
                          </option>
                        </select>
                      </form>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="button_05 button_black"
                        value={""}
                        onClick={getSearch}
                      >
                        검색
                      </button>
                      <button
                        type="button"
                        className="button_05 button_gray_line"
                        value={""}
                        onClick={getClear}
                      >
                        초기화
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </article>

            <table className="table_box_01">
              <colgroup>
                <col width="60px" />
                <col width="220px" />
                <col width="100px" />
                <col width="120px" />
                <col width="100px" />
                <col width="140px" />
                <col width="100px" />
                <col width="130px" />
                <col width="175px" />
              </colgroup>

              <thead>
                <tr>
                  <th>No</th>
                  <th>기프트 아이디</th>
                  <th>기프트 이미지</th>
                  <th>가격</th>
                  <th>
                    누적판매수량/
                    <br />
                    총발행수량
                  </th>
                  <th>누적판매금액</th>
                  <th>상태</th>
                  <th>등록일</th>
                  <th>수정/판매현황</th>
                </tr>
              </thead>

              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.giftId}</td>
                    <td>
                      <img
                        src={
                          item.thumbnailFileUrl === Config.API_SERVER
                            ? ""
                            : item.thumbnailFileUrl
                        }
                      />
                    </td>
                    <td>{formatNumNumber(item.price)}원</td>
                    <td>
                      {" "}
                      {formatNumNumber(item.countSold)}/
                      {item.countIssued === null
                        ? ""
                        : formatNumNumber(item.countIssued)}
                    </td>
                    <td>{formatNumNumber(item.countSold * item.price)}원</td>
                    <td>{item.stateDesc}</td>
                    <td>
                      {new Date(item.regDttm).toISOString().substring(0, 10)}
                    </td>
                    <td>
                      <button
                        type="button"
                        className="button_04 button_gray"
                        onClick={(e) => {
                          modGift(item.giftNo);
                        }}
                      >
                        수정
                      </button>
                      <button
                        type="button"
                        className="button_04 button_gray_line"
                        onClick={(e) => {
                          sales(item.giftId);
                        }}
                      >
                        판매현황
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <p className="table_01">총건수 : {total}건</p>

            {/* <article className="page_box"> */}
            <ul className="page_box">
              <Pagination
                totalPage={totalPage}
                onPageChange={pageChange}
              />
            </ul>
            {/* </article> */}

            <article className="button_box_02">
              <div>
                <button
                  type="button"
                  className="button_01 button_03 button_black_line"
                  onClick={(e) => {
                    sales(0);
                  }}
                >
                  기프트 판매현황
                </button>
                <button
                  type="button"
                  className="button_01 button_001 button_03 button_black_line "
                  onClick={(e) => {
                    navigate("/gift-refund");
                  }}
                >
                  환불 내역
                </button>
              </div>
              <button
                type="button"
                className="button_01 button_03 button_red"
                onClick={(e) => {
                  modGift(0);
                }}
              >
                기프트 등록
              </button>
            </article>
          </section>
        </section>
      </main>
    </>
  );
}
